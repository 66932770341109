import { Toast as PrimeReactToast, ToastMessage, ToastProps } from "primereact/toast"
import React, { createRef } from "react"

const toastRef = createRef<PrimeReactToast>()

export function GlobalToast(props: {
  position: ToastProps['position']
}) {
  return (
    <PrimeReactToast ref={toastRef} position={props.position} />
  )
}

function withToast<T>(callback: (t: PrimeReactToast) => T) {
  if (!toastRef.current) {
    console.error("toast not initialized")
    return
  }
  callback(toastRef.current)
}

export namespace Toast {
  export function show(message: ToastMessage | ToastMessage[]) {
    return withToast(t => t.show(message))
  }

  export function replace(message: ToastMessage | ToastMessage[]) {
    return withToast(t => t.replace(message))
  }

  export function remove(message: ToastMessage | ToastMessage[]) {
    return withToast(t => t.remove(message))
  }

  export function clear() {
    return withToast(t => t.clear())
  }
}
