import { lazy } from 'react'
import PublishUtils from "../../../helpers/PublishUtils"

export const editableTableDescription = {
  name: 'Editable table',
  component:lazy(() => import('./EditableTable' )),
  label: 'Editable table widget (PROTOTYPE)',
  variables: [
    {
      name: 'publishVariable',
      type: 'text',
      label:
        'Variable in which item URI is published, when a link in the table is clicked.'
    },
    {
      name: 'tableSize',
      type: 'select',
      label: 'Table display size',
      options: [
        { value: 'small', label: 'small' },
        { value: 'normal', label: 'normal' },
        { value: 'large', label: 'large' }
      ]
    },
    {
      name: 'editMode',
      type: 'select',
      label: 'Table edit mode',
      options: [
        { value: 'disabled', label: 'Edit disabled' },
        { value: 'cell', label: 'Per cell' },
        { value: 'row', label: 'Per row' }
      ]
    },
    {
      name: 'filterMode',
      type: 'select',
      label: 'Table filter mode for columns',
      options: [
        { value: 'disabled', label: 'Column filters disabled' },
        { value: 'row', label: 'Basic filters' },
        { value: 'menu', label: 'Filtering with menus (multiple constraints)' }
      ]
    },
    {
      name: 'defaultFilterMatchMode',
      type: 'select',
      label: 'Default filter match mode',
      options: [
        { value: 'contains', label: 'Contains' },
        { value: 'startsWith', label: 'Starts with' },
        { value: 'equals', label: 'Equals' }
      ]
    },
    {
      name: 'globalFilterFields',
      type: 'text',
      label: 'Columns for global filter search bar, comma separated. Keep empty to hide global search bar.'
    },
    {
      name: 'globalFilterPlaceholder',
      type: 'text',
      label: 'Global filter search bar placeholder text. E.g. "Search for equipment..."'
    },
    {
      name: 'exportEnabled',
      type: 'boolean',
      label: 'Enable CSV export'
    },
    {
      name: 'deletebuttonrule',
      type: 'text',
      label: 'uri or tag for rule when pushing delete icon',
    },
    {
      name: 'query',
      type: 'yasgui',
      label:
        `common variables: ?uri ?graph
        extension variables: ?variable ?variablePRED ?variableGRAPH ?variableURI ?variableURL ?variableHIDDEN ?variableDATATYPE ?variableINPUTTYPE ?variableDATATYPE`,
      helpComponent: EditableTableQueryHelp
    },
    PublishUtils.getTimestampParametersDef()
  ],
}

function EditableTableQueryHelp() {
  return (
    <table>
      <tbody>
      <tr>
        <th colSpan={3}><b>Global variables (not shown in table)</b></th>
      </tr>
      <tr>
        <th>
          <code>?graph</code>
        </th>
        <td>uri</td>
        <td>
          The graph related to the values of the cell.
        </td>
      </tr>
      <tr>
        <th>
          <code>?uri</code>
        </th>
        <td>uri</td>
        <td>
          The default subject for all values of the cell.
        </td>
      </tr>
      <tr>
        <th colSpan={3}><b>Extension variables</b></th>
      </tr>
      <tr>
        <th>
          <code>?variable</code>
        </th>
        <td>uri or literal</td>
        <td>
          This column name will be used in the column header ('variable' in this example). Its values will be displayed
          as cell.
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableHIDDEN</code>
        </th>
        <td>any</td>
        <td>
          Hides the variable in the table. In rules/commands, variable is exposed without HIDDEN suffix.
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableURI</code>
        </th>
        <td>uri</td>
        <td>
          Sets the subject for the cell (overrides the default subject). Also makes it clickable and used as
          publishVariable.
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableURL</code>
        </th>
        <td>uri or string literal</td>
        <td>
          Makes the cell a hyperlink. The URL will be opened in a new tab.
        </td>
      </tr>
      <tr>
        <th>
          <code>?variablePRED</code>
        </th>
        <td>uri</td>
        <td>
          Predicate related to the cell.
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableGRAPH</code>
        </th>
        <td>uri</td>
        <td>
          Graph related to the cell.
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableINPUTTYPE</code>
        </th>
        <td>string literal</td>
        <td>
          Which input component to use for editing. Currently available options: "text", "textarea"
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableDATATYPE</code>
        </th>
        <td>uri</td>
        <td>
          Which data type the cell should have. Example: &lt;http://www.w3.org/2001/XMLSchema#string&gt;.
          Currently only xsd:string is supported. In the future this will be needed to know the datatype
          for <i>new</i> cells.
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableCOLOR</code>
        </th>
        <td>string literal</td>
        <td>
          CSS color of the cell text. E.g. "blue", "#ff3311", "rgb(125, 125, 125)"
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableBACKGROUND</code>
        </th>
        <td>string literal</td>
        <td>
          CSS background color of the cell. E.g. "blue", "#ff3311", "rgb(125, 125, 125)"
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableBORDER</code>
        </th>
        <td>string literal</td>
        <td>
          CSS border of the cell. E.g. "2px solid red"
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableICON</code>
        </th>
        <td>string literal</td>
        <td>
          <p>
            CSS border of the cell. E.g. "2px solid red". E.g. "fa-cog", "pi-check". Available icon libraries:{' '}
            <a href="https://primereact.org/icons/#list" target="_blank" rel="noopener noreferrer">Primereact Icons</a>,{' '}
            <a href="https://fontawesome.com/search?m=free&o=r" target="_blank" rel="noopener noreferrer">Font Awesome</a>.
          </p>
          <p>
            If the value is a boolean literal, the icon will only be shown if the value is true.
          </p>
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableICONSIZE</code>
        </th>
        <td>string literal</td>
        <td>
          CSS size of the icon. E.g. "25px", "16pt", "2em"
        </td>
      </tr>
      <tr>
        <th>
          <code>?variableICONCOLOR</code>
        </th>
        <td>string literal</td>
        <td>
          CSS color of the icon. E.g. "blue", "#ff3311", "rgb(125, 125, 125)"
        </td>
      </tr>
      </tbody>
    </table>
  )
}

